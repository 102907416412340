import React from "react"
import { Helmet } from "react-helmet"
import "typeface-noto-serif-jp"
import "./style.css"

export default function Home() {
  return (
        <div className="blog-entries">
          <div className="blog-entry">
            <div className="blog-entry-date">February 2021</div>
            <div className="blog-entry-title"><a href="/upload-your-reading-life">Upload Your Reading Life</a></div>
          </div>
          <div className="blog-entry">
            <div className="blog-entry-date">October 2020</div>
            <div className="blog-entry-title"><a href="/ddo-monitoring-strawberries">Monitor Strawberries</a></div>
          </div>
      </div>
  );
}
